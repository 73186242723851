@import "variables.module";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-black;
  perspective: 2000px;
  transition: 0.2s cubic-bezier(0.4, 0.4, 0.4, 0.8);
  z-index: 21;

  .bgImage {
    position: fixed;
    width: 100vw;
    height: 100vh;
    filter: brightness(70%);

    > span {
      border-radius: 16px;
    }
  }

  .innerContainer {
    width: 100%;
    max-width: $width-max;
    padding: 0 20px;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .pagination {
        display: flex;
        width: 100%;
        padding: 12px 24px;
        gap: 4px;
        position: absolute;
        &.paginationBlack {
          > div {
            opacity: 1;
            &.selected {
              background-color: $color-black;
            }
          }
        }

        > div {
          display: flex;
          background: $color-white;
          opacity: 0.3;
          height: 2px;
          width: 10px;
          flex: 1;
          border-radius: 1px;

          &.selected {
            opacity: 1;
          }
        }
      }

      > button {
        margin-top: 18px;
        align-self: flex-end;
      }
    }

    .previewHeader {
      width: 100%;
      padding: 27px 0;
      position: relative;

      &Text {
        position: fixed;
        top: 110px;
        left: 50%;
        transform: translateX(-50%);
        color: $color-white;
        font-size: 18px;
        font-weight: 700;
      }

      > button {
        position: fixed;
        top: 22px;
        right: 24px;
      }
    }

    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //gap: 8px;

      > .logo {
      }

      > button {
        align-self: flex-end;
      }

      .owner {
        margin-top: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .text {
        max-height: 300px;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        overflow-y: scroll;
        margin-top: 32px;
        padding-bottom: 40px;
        white-space: pre-line;
        -webkit-mask-image: -webkit-gradient(
          linear,
          left 85%,
          left bottom,
          from(black),
          to(rgba(0, 0, 0, 0))
        );
      }

      .author {
        margin-top: 28px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
      }

      .label {
        font-size: 13px;
        font-weight: 600;
        margin-right: 7px;
      }

      .userItem {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &From {
          justify-content: space-between;
        }
        .nameLabel {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 160px;
          font-size: 18px;
          font-weight: 700;
          padding: 0 7px;
        }

        .from {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }

    footer {
      width: 300px;
      height: 150px;

      .object {
        position: relative;
        top: 50px;
        left: 120px;
        right: 0;
        margin: auto;
        opacity: 0;
        width: 50px;
        height: 50px;
        background-color: rgba(255, 255, 255, 0.8);
        border: solid 1px #fff;
        border-radius: 50%;
        animation-name: moving-fadein-out;
        animation-duration: 2.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      }
      @keyframes moving-fadein-out {
        0% {
          opacity: 0;
          left: 120px;
        }
        50% {
          opacity: 1;
        }
        90% {
          opacity: 0;
        }
        100% {
          left: -120px;
        }
      }

      .navigation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 280px;
        height: 50px;
        margin: auto;
        /* background-color: rgba(255,255,255,.2); */
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 10%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 90%
        );
        border-radius: 64px;
        animation-name: fadein-out;
        animation-duration: 2.5s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      }
      .navigation > span {
        display: block;
        color: #fff;
        font-size: 16px;
        text-align: center;
      }
      @keyframes fadein-out {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        90% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
}
