@import "variables.module";
@import "gridIcons.module";

.emptyLayout {
  width: 100%;
  max-width: $width-max;
  padding: 0 16px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.shadowRoot {
  width: 100%;
  padding: 0 2px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  margin-bottom: 40px;
  position: relative;

  .gridItem {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(149, 157, 165, 0.24) 0 6px 20px;
    border-radius: 6px;
    overflow: visible;

    &.selected {
      padding: 1px;
      background-color: transparent;
      box-shadow: none;
      z-index: 10;
    }

    &.hasLink {
      cursor: pointer;
    }

    &.layoutResize {
      .buttonDrag {
        &.buttonDrag {
          bottom: -10px;
          left: -15px;
          right: initial;
          margin: initial;
        }
      }
    }

    &Content {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      overflow: hidden;
      transition: transform 0.3s ease;

      &Selected {
        transform: translateY(-2px);
        border: 2px solid var(--blue, #242be7);
        border-radius: 8px;
        box-shadow: rgb(149, 157, 165, 0.24) 0 6px 20px;

        &.layoutResize {
          overflow: visible;
        }
      }
    }

    .imageItem {
      position: relative;
      width: 100%;
      height: 100%;

      &Caption {
        position: absolute;
        left: 5px;
        bottom: 5px;
        font-size: 12px;
        padding: 6px 7px;
        color: $color-black;
        background-color: $color-white;
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &Volume {
        width: 34px;
        height: 34px;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 5px;
      }

      &Container {
        position: relative;
        > video {
          width: 100%;
          height: 100%;
        }
      }
    }

    .linkItem {
      height: 100%;
      padding: 14px;
      overflow: hidden;
      background-color: $color-white;

      &Selected {
        padding: 12px;
      }

      &Header {
        &Icon {
          width: 45px;
          height: 45px;
          flex-shrink: 0;
          @media screen and (min-width: $tab-min) {
            width: 50px;
            height: 50px;
          }
        }

        &TitleSection {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          margin-bottom: 10px;

          .title {
            font-size: 14px;
            font-family: "Inter", "Noto Sans Jp", sans-serif;
            line-height: 115%;
            min-height: 17px;
            height: 17px;
            max-height: 30px;
            overflow: hidden;
            word-break: break-word;
          }

          .accountId {
            margin-top: 4px;
            font-size: 13px;
            color: $color-gray;
          }
        }
      }

      &Body {
        height: 42px;
        max-height: 42px;
        margin-top: 10px;
        overflow: hidden;
        word-break: break-word;
        font-size: 13px;
        line-height: 125%;
        color: $color-gray-lighter-400;

        &.sns {
          color: $color-gray-lighter-600;
        }
      }

      &ThumbnailContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &Thumbnail {
        width: 100%;
        height: 340px;
        position: relative;
      }

      &.layout4x1 {
        .linkItemHeader {
          height: 100%;
          display: flex;
          align-items: center;

          &TitleSection {
            margin: 0 0 0 10px;
            overflow: hidden;

            .title {
              height: 100%;
              line-height: inherit;
            }

            > span {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }

      &.layout2x2 {
        .linkItemHeader {
          &TitleSection {
            .title {
              max-height: 30px;
            }
          }
        }
      }

      &.layout4x4 {
        &.ogp {
          .linkItemHeader {
            display: flex;
            align-items: center;

            .linkItemHeaderTitleSection {
              margin-left: 10px;
              margin-bottom: 0;

              .title {
                max-height: 45px;
              }
            }
          }
        }
      }
    }

    .textItem {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      padding: 16px;
      word-break: break-word;

      &Selected {
        padding: 14px;
      }

      &Edit {
        padding: 6px;
      }

      .fontWhite {
        * {
          color: #ffffff;
        }
      }

      .fontBlack {
        color: #1d1d1d;
      }

      .textContent {
        width: 100%;
        flex-direction: column;

        p {
          display: block;
        }
      }

      .quillEditor {
        width: 100%;
        height: 100%;
        overflow: scroll;
        border-radius: 8px;

        > div {
          width: 100%;
        }
      }
    }

    img {
      border-radius: 6px;
    }

    button {
      width: 30px;
      height: 30px;
      flex-shrink: 0;
      border-radius: 50%;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      &.buttonTrash {
        top: -14px;
        left: -13px;
      }

      &.buttonEdit {
        top: -14px;
        right: -13px;
      }

      &.buttonEditing {
        top: -14px;
        right: -13px;
        background-color: #242be7;
      }

      &.buttonDrag {
        bottom: -11px;
        left: 0;
        right: 0;
        margin: auto;
        cursor: move;
      }
    }
  }
}

.frameContainer {
  width: 100%;

  .frame {
    width: 100%;
    height: 100%;
    border: none;
  }
}
