@import "variables.module";

.postItem {
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  width: 100%;
  //display: flex;
  //justify-content: space-between;
  //align-items: center;
  //gap: 8px;
}

.avatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  box-shadow: 0 0 0 1px #fff;
}

.name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1d1d1d;
}

.id {
  font-style: normal;
  font-size: 12px;
  color: #aaaaaa;
}

.postText {
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.declinedText {
  width: 100%;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  > p {
    margin: 5px 10px 16px;
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  > span {
    display: block;
    text-align: center;
    font-size: 11px;
    color: $color-gray-darker;
    line-height: 20px;
  }
}

.description {
  display: flex;
  flex-direction: column;

  &Header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 15px;
  }

  &Text {
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .firstRow {
    display: flex;
    justify-content: space-between;
  }

  .secondRow {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }

  .like {
    display: flex;
    align-items: center;
    gap: 4px;

    .likeIcon {
      display: flex;
      width: 18px;
      height: 18px;
      background-image: url("/images/like-icon.svg");
      background-repeat: no-repeat;

      &Liked {
        background-image: url("/images/like-icon-liked.svg");
      }
    }

    .likeCount {
      font-size: 13px;
      line-height: 13px;
      margin-left: 4px;
      width: 50px;
    }
  }

  .date {
    font-size: 12px;
    color: #aaa;
  }
}

.detail {
  .like {
    display: flex;
    align-items: center;
    gap: 4px;

    .likeIcon {
      display: flex;
      width: 18px;
      height: 18px;
      margin-top: 2px;
      background-image: url("/images/like-icon.svg");
      background-repeat: no-repeat;

      &Liked {
        background-image: url("/images/like-icon-liked-white.svg");
      }
    }

    .likeCount {
      margin-left: 5px;
      font-size: 14px;
      width: 50px;
    }

    &Black {
      .likeIcon {
        filter: invert(100%);
      }
    }
  }
}
