.scrollContainer {
  width: 100%;
  position: fixed;
  bottom: 16px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 22px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-grow: 1;
  height: 50px;
  z-index: 11;
}

.createIconSection {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 16px;
  left: 0;
  right: 0;
}

.invitationButton {
  margin-left: 16px;
}

.iconBox {
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--black-black, #1d1d1d);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 16px;
  left: 0;
  right: 0;
  margin: auto;
}

.iconButton {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 44px;
  background: var(--black-black, #1d1d1d);

  &.selected {
    background: var(--white-white, #ffffff);

    img {
      filter: invert(100%);
    }
  }

  &.selectedColor {
    background: var(--white-white, #ffffff);
  }
}

.iconGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  button {
    width: 36px;
  }
}

.createBox {
  width: 124px;
  height: 52px;

  > button {
    width: 36px;
  }
}

.resizeBox {
  width: 186px;
  height: 50px;

  > button {
    width: 34px;
    height: 34px;
  }
}

.editBox {
  width: 60px;
  height: 50px;
  flex-shrink: 0;
  padding: 0 20px;
  gap: 15px;

  &Text {
    width: 568px;
  }

  button {
    width: 34px;
    height: 34px;
  }
}

.hideScrollBar {
  /* For WebKit-based browsers (Chrome, Safari) */
  -webkit-overflow-scrolling: touch; /* Allows smooth scrolling on iOS devices */
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }

  /* For Internet Explorer and Edge */
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Edge */
}

.slide {
  &Enter {
    transform: translateY(100%);
    opacity: 0;
  }

  &EnterActive {
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }

  &Exit {
    transform: translateY(0);
    opacity: 1;
  }

  &ExitActive {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }
}

.fade {
  &Enter {
    opacity: 0;
  }

  &EnterActive {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }

  &Exit {
    opacity: 1;
  }

  &ExitActive {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
}

.cardSelected {
  &Enter {
    opacity: 0;
  }

  &EnterActive {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }

  &Exit {
    opacity: 1;
  }

  &ExitActive {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
}
